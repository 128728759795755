const FEATURE_FLAG_NAME = {
  JQ_4153_REMOVE_SORTING_OPTIONS: 'JQ-4153_remove-sorting-options',
  JQ_4156_SIMILAR_DESIGNS_BUTTON: 'JQ-4156_similar-designs',
  JQ_4157_WALLART_DESIGN_ONLY_IMAGES: 'JQ-4157_wallart_design_only_images',
  JQ_4168_MOBILE_PLP_CHANGES: 'JQ-4168_mobile_plp_changes',
} as const;

type FeatureFlagName = typeof FEATURE_FLAG_NAME[keyof typeof FEATURE_FLAG_NAME] | string;

export {
  FEATURE_FLAG_NAME,
  type FeatureFlagName,
};
